@import '~styles/vars.less';

.confirm {
  &__icon {
    margin-top: @grid-size / 2;
    margin-bottom: @grid-size;

    font-size: 40px;
  }

  &__text {
    margin-bottom: @grid-size;
  }

  &__note {
    margin-bottom: @grid-size * 2;

    color: @color-text-500;
  }

  .button {
    border-radius: @border-radius / 2;
  }
}
