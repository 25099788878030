@import '~styles/vars.less';

.numbers-quiz {
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    flex-grow: 1;
  }

  &__bottom {
    position: relative;
  }

  &__typing-value {
    margin-bottom: @grid-size * 2;

    text-align: center;
    font-size: 60px;
    font-weight: 600;

    transition: color 0.2s;

    &--success {
      color: @color-success;
    }
  }

  &-keyboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    gap: @grid-size / 2;
    width: 160px;

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 48px;
      height: 48px;

      background: @color-neutral-200;
      border-radius: @border-radius;

      font-size: 20px;
      font-weight: 600;
      color: @color-primary;

      cursor: pointer;

      transition: color 0.2s, background 0.5s;

      &--failed {
        background: @color-error;
        color: #fff;
      }
    }
  }

  &__config {
    box-sizing: border-box;

    width: 100%;
    padding: @grid-size;

    background: @color-neutral-200;
    border-radius: @border-radius;
  }

  &-button-play {
    display: inline-block;

    width: 56px;
    height: 56px;
    margin: 0 4px;

    border-radius: 100%;
    background: @color-neutral-200;

    cursor: pointer;

    &.disabled {
      opacity: 0.5;

      cursor: default;
    }
  }

  &-button-play {
    background: @color-neutral-200 url('~images/svg/icon-play.svg') center center no-repeat;

    &--playing {
      position: relative;

      background-image: none;

      &::before {
        position: absolute;
        display: block;
        content: '';

        width: 16px;
        height: 16px;
        top: 20px;
        left: 20px;

        background: @block-blue;
        border-radius: 2px;
      }
    }
  }
}

.form-checkbox {
  position: relative;
  overflow: hidden;
  display: block;

  cursor: pointer;

  & + & {
    margin-top: @grid-size;
  }

  &__label {
    position: relative;
    display: inline-block;
    box-sizing: border-box;

    width: 100%;
    padding: 8px 72px 8px 0;

    color: @color-primary;
    font-weight: 400;
  }

  &__toggler {
    display: block;
    position: absolute;
    box-sizing: border-box;

    width: 56px;
    height: 32px;
    right: 0;
    top: 1px;

    background: @color-neutral-500;
    border-radius: 100px;

    transition: background 0.2s;

    &::before {
      content: '';
      display: block;
      position: absolute;

      width: 26px;
      height: 26px;
      top: 3px;
      left: 3px;

      border-radius: 100%;
      background: #fff;

      transition: left 0.2s;
    }
  }

  input {
    position: absolute;

    left: -10000px;

    opacity: 0;
  }

  // input:checked ~ &__label {
  // }

  input:checked ~ &__label &__toggler {
    background: @color-primary;

    &::before {
      left: 27px;
    }
  }
}
