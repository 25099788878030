@import '~styles/vars.less';

.navbar {
  display: flex;
  box-sizing: border-box;

  // justify-content: space-between;
  justify-content: space-around;
  padding: 18px 20px;

  // border-radius: @border-radius;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 6px 32px rgba(12, 4, 41, 0.1);

  &__item {
    position: relative;

    padding-top: 32px;
    width: 80px;

    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-decoration: none;
    text-align: center;

    color: @color-text-500;

    cursor: pointer;

    span {
      display: block;
      position: absolute;

      width: 100%;
      height: 100%;
      top: 0;

      text-align: center;
    }

    &.active {
      color: @text-blue;

      cursor: default;
    }
  }
}
