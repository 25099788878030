@import 'styles/vars.less';

.button {
  display: block;
  box-sizing: border-box;

  padding: @grid-size;
  min-height: 56px;

  border: none;
  border-radius: @border-radius;
  outline: none;

  color: #fff;
  font-family: 'Segoe UI';
  font-size: 16px;
  font-weight: 600;
  // line-height: 10px;
  text-decoration: none;
  text-align: center;

  cursor: pointer;

  &--default {
    background: @color-neutral-200;

    color: @color-primary;
  }

  &--transparent {
    background: none;

    font-weight: 400;
    color: @text-blue;
  }

  &--primary, &.primary {
    background-color: @color-primary;

    color: #fff;
  }

  &--secondary, &.secondary {
    background-color: @color-secondary;

    color: #fff;
  }

  &--disabled {
    cursor: default;

    background-color: @color-neutral-300;

    color: @color-neutral-500;
  }

  &--disabled &--secondary {}

  &--full-width {
    width: 100%;
  }

  &--size-small {
    padding: 8px 16px;
    min-height: 35px;

    font-size: 14px;
  }

  .paper &,
  .border-radius-default & {
    border-radius: @border-radius / 2;
  }
}
