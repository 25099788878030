@import './vars.less';

html, body, #app {
  height: 100%;

  touch-action: manipulation;
}

#app {
  overflow: hidden;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow: hidden;

  margin: 0;

  background: @color-neutral-200;
};

i {
  font-style: normal;
}

p {
  margin: 0;
}

p + p {
  margin-top: 8px;
}

a {
  color: @text-blue;
}

.clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

.overlay, .splash-screen {
  &::before {
    content: '';
    z-index: 9998;

    display: block;
    position: fixed;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: @block-blue url('~images/svg/login-logo.svg') center center no-repeat;
  }
}

// TODO: figure out how to use it, it affects desktop too
@media only screen and (orientation: landscape) and (max-height: 500px) {
  #app, #modal {
    display: none;
  }

  body {
    background: @block-blue;
  }

  body::before {
    content: '';
    z-index: 9998;

    display: block;
    position: fixed;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: @block-blue url('~images/svg/icon-to-landscape.svg') center center no-repeat;
  }
}

.h1 {
  font-size: 20px;
  font-weight: 700;
}

.h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.paper {
  // box-sizing: border-box;

  padding: @grid-size;

  background: #fff;
  border-radius: @border-radius;
}

.gap {
  &-2x {
    margin: @grid-size * 2;
  }

  &-1x {
    margin: @grid-size;
  }

  &-05x {
    margin: @grid-size / 2;
  }

  &-t {
    &-2x {
      margin-top: @grid-size * 2;
    }

    &-1x {
      margin-top: @grid-size;
    }

    &-05x {
      margin-top: @grid-size / 2;
    }
  }

  &-b {
    &-2x {
      margin-bottom: @grid-size * 2;
    }

    &-1x {
      margin-bottom: @grid-size;
    }

    &-05x {
      margin-bottom: @grid-size / 2;
    }
  }

  &-l {
    &-2x {
      margin-left: @grid-size * 2;
    }

    &-1x {
      margin-left: @grid-size;
    }

    &-05x {
      margin-left: @grid-size / 2;
    }
  }

  &-r {
    &-2x {
      margin-right: @grid-size * 2;
    }

    &-1x {
      margin-right: @grid-size;
    }

    &-05x {
      margin-right: @grid-size / 2;
    }
  }
}

.border-radius-default {
  border-radius: @border-radius;
}

.device {
  &-ios {}

  &-android {}
}
