@import '~styles/vars.less';

.exercise-gap-option {
  box-sizing: border-box;
  position: relative;

  height: 40px;
  min-width: 46px;
  padding: 9px 16px 11px;

  background: @color-neutral-200;
  border-radius: @border-radius;
  border: 1px solid @color-neutral-200;

  line-height: 20px;
  font-size: 14px;
  text-align: center;
  color: @color-primary;

  cursor: pointer;

  transition: color 0.2s, background 0.5s, border-color 0.5s;

  &--used {
    height: 40px;

    border-color: @color-neutral-300;

    background: #fff;

    color: transparent;

    cursor: default;
  }

  &--failed {
    border-color: @color-error;
    background: @color-error;
    color: #fff;
  }

  &--size-large {
    height: 48px;
    padding-top: 12px;

    font-size: 20px;
    font-weight: 600;
  }

  &::before {
      display: block;
      content: attr(data-number);
      position: absolute;

      top: 4px;
      right: 8px;

      font-family: 'Space Mono';
      font-weight: 700;
      font-size: 12px;
      line-height: 10px;
      color: @color-neutral-500;
    }
}
