@import '~styles/vars.less';

.modal-overlay {
  position: fixed;
  z-index: 9999;
  overflow: auto;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;

  background: rgba(0, 0, 0, 0.3);

  backdrop-filter: blur(8px);

  &--position-bottom {
    justify-content: flex-end;
  }
}

.device-iphone-gte-10 .modal__inner {
  padding-bottom: 36px;
}

.modal {
  width: 100%;

  font-family: 'Segoe UI';
  font-weight: 400;
  font-size: 16px;
  text-align: center;

  &__wrapper {
    position: relative;
    box-sizing: border-box;

    min-width: @min-width;
    max-width: @max-width;
    margin: 0 auto;
    padding: @grid-size;
  }

  &__inner {
    position: relative;
    // box-sizing: border-box;

    margin: 0 auto;
    padding: 0 @grid-size @grid-size;

    background: white;
    border-radius: @border-radius;
  }

  &__header {
    min-height: 56px;
  }

  &--position-bottom &__wrapper {
    padding: initial;
  }

  &--position-bottom &__inner {
    border-radius: @border-radius @border-radius 0 0;
  }

  &__content {
    padding-top: @grid-size;
  }

  &__header + &__content {
    padding-top: 0;
  }

  &__title {
    padding: @grid-size @grid-size 0;
    // margin-bottom: @grid-size;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
  }

  &__text {
    font-weight: 400;
    line-height: 24px;
    text-align: center;

    & + * {
      margin-top: 16px;
    }
  }

  &__footer + &__content {
    margin-top: @grid-size;
  }

  &__buttons {
    &--no-wrap {
      display: flex;

      .button + .button {
        margin-left: 8px;
      }
    }
  }

  &__close {
    position: absolute;

    top: @grid-size;
    right: @grid-size;
    width: 24px;
    height: 24px;

    border-radius: 100%;

    background: @color-neutral-200;

    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;

      width: 14px;
      height: 2px;
      top: 11px;
      left: 5px;

      border-radius: 8px;
      background: @color-primary;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__back-button {
    display: flex;
    position: absolute;

    left: 0;
    top: 0;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;

    &-icon {
      cursor: pointer;
    }
  }
}
