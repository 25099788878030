@import '~styles/vars.less';

.course-topic {
  &__head {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    margin-bottom: @grid-size * 2;
  }

  &__title {}

  &__progress {
    font-family: 'Space Mono';
  }

  &__subtopics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding-bottom: 8px;
    margin-bottom: -@grid-size * 2;

    .course-topic-subtopic {
      width: 50%;
      margin-bottom: @grid-size * 2;
    }
  }

  & + & {
    margin-top: @grid-size;
  }

  &--locked &__title {
    color: @color-text-500;
  }
}

.course-topic-subtopic {
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: @grid-size / 2;

  cursor: pointer;

  &__progressbar {}

  &__icon {
    position: relative;
    display: flex;
    justify-content: center;

    align-items: center;
    width: 64px;
    height: 64px;

    background: @color-neutral-200;
    border-radius: 100%;

    font-size: 40px;
  }

  &__title {
    max-width: 80%;

    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }

  &--no-float {
    flex-basis: 100%;
  }

  &--completed &__icon {
    width: 80px;
    height: 80px;

    background: @color-secondary-linear;
  }

  &--completed .radial-progress-bar__scale,
  &--completed .radial-progress-bar__scale-fill {
    display: none;
  }

  &--locked {
    cursor: default;
  }

  &--locked &__icon {
    width: 80px;
    height: 80px;
  }

  &--locked &__icon-text {
    opacity: .8;
    filter: grayscale(100%);
  }

  &--locked &__title {
    color: @color-neutral-500;
  }
}
