@import '~styles/vars.less';

.exercise-gap-placeholder {
  position: relative;
  box-sizing: border-box;

  width: 55px;
  height: 40px;

  border: 1px solid @color-neutral-300;
  border-radius: @border-radius;

  background: #fff;

  &__number {
    position: absolute;

    top: 7px;
    left: 8px;

    font-family: 'Space Mono';
    font-weight: 700;
    font-size: 12px;
    line-height: 10px;
    color: @color-neutral-500;
  }

  &.inline {
    display: inline-block;

    margin: 0 12px;

    &::before {
      content: '-';

      font-size: 16px;
      color: transparent;
    }
  }

  &.active {
    border-width: 2px;
    border-color: @color-secondary;
  }

  &--value-shown {
    min-width: 46px;
    width: auto;
    padding: 9px 16px 11px;

    background: @color-neutral-200;
    border-color: @color-neutral-200;

    line-height: 20px;
    font-size: 14px;
    text-align: center;
    color: @color-primary;

    &.inline::before {
      content: '';
    }
  }

  &--success {
    background: @block-green;
    border-color: @block-green;
    color: #fff;
  }
}
