@import '~styles/vars.less';

.chip {
  display: inline-block;

  padding: 9px 16px 11px;

  border-radius: @border-radius;
  background: @color-neutral-0;

  font-size: 16px;
  line-height: 20px;
  color: @color-text-500;

  cursor: pointer;

  .paper & {
    border-radius: @border-radius / 2;
  }

  &.active {
    color: #fff;
    background: @color-primary;
  }

  &.disabled {
    opacity: 0.5;
  }

  &__number {
    display: inline-block;

    margin-left: 6px;
    padding: 3px 5px 4px;

    background: @block-blue;
    border-radius: 100px;

    font-weight: 700;
    font-size: 12px;
    line-height: 10px;
    color: #fff
  }
}
