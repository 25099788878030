@import '~styles/vars.less';

.radial-progress-bar {
  position: relative;

  &__svg {
    transform: rotate(90deg);
  }

  &__scale,
  &__scale-fill {
    fill: none;
  }

  &__scale {
    stroke: @color-neutral-200;
  }

  &__scale-fill {
    stroke: @color-secondary;
    stroke-linecap: round;

    animation: percent 1.5s linear;
    animation-delay: 1s;

    .level-low & {
      stroke: @block-yellow;
    }

    .level-high & {
      stroke: @block-green;
    }
  }

  &__content {
    position: absolute;
    display: flex;
    justify-content: center;

    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &--highlight-1 &__scale {
    stroke: @color-neutral-300;
  }
}
