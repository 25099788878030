@import '~styles/vars.less';

.profile {
  &-course-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__edit-button {
      padding: 4px 8px 5px;

      border-radius: @border-radius;
      background: @color-primary;

      color: #fff;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-decoration: none;

      cursor: pointer;
    }
  }

  &-settings {
    &__item {
      cursor: pointer;
    }
  }

  &__course-summaries {
    display: flex;
    flex-direction: row;
  }

  &-course-summary {
    width: 100%;

    text-align: center;

    &__title {
      color: @text-grey;
    }

    &__number {
      margin-top: @grid-size / 2;

      font-size: 30px;
      font-weight: 700;
      // color: @text-blue;
    }
  }
}
