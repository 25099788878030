@import '~styles/vars.less';

.record-button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;

  border-radius: 100%;
  background: @block-blue;

  cursor: pointer;

  &--success {
    background: @block-green;
  }

  &--pulsing {
    box-shadow: 0 0 0 0 rgba(@block-blue, .5);
    animation: record-button-pulse 1.2s infinite;
  }

  &--disabled {
    background: @block-light-blue;

    cursor: default;
  }
}

// https://codepen.io/zachacole/details/ojVqbg
.icon-recording {
  display: flex;
  flex-direction: row;

  align-items: center;

  &__item {
    position: relative;
    display: inline-block;

    height: 10px;
    width: 2px;
    margin: 0 1px;

    background: #fff;
    border-radius: 10px;

    animation: record-button-wave 2.5s ease-in-out infinite;
  }

  &__item-2 {
    animation-delay: 0.3s;
  }

  &__item-3 {
    animation-delay: 0.1s;
  }

  &__item-4 {
    animation-delay: 0.2s;
  }

  &__item-5 {
    animation-delay: 0.5s;
  }

  &__item-6 {
    animation-delay: 0.4s;
  }
}

@keyframes record-button-wave {
  0%,
  100% {
    height: 10px;
    // transform: scaleY(1);
  }
  16.67% {
    height: 20px;
    // transform: scaleY(3);
  }
  33.33% {
    height: 10px;
    // transform: scaleY(1);
  }
  50% {
    height: 20px;
    // transform: scaleY(3);
  }
  66.67% {
    height: 10px;
    // transform: scaleY(1);
  }
  83.34% {
    height: 20px;
    // transform: scaleY(3);
  }
}

@keyframes record-button-pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(#5a99d4, 0);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
