@import '~styles/vars.less';

.course-block {
  display: flex;
  flex-direction: row;

  gap: @grid-size;
  padding: @grid-size;

  &__flag {
    overflow: hidden;

    width: 64px;
    height: 48px;

    border-radius: @border-radius;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);

    .paper & {
      border-radius: @border-radius / 2;
    }

    &--en {
      background-image: url('~images/svg/flag-en.svg');
    }

    &--ee {
      background-image: url('~images/svg/flag-ee.svg');
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    margin-bottom: 12px;

    font-weight: 600;
    font-size: 16px;
    // line-height: 24px;
  }

  &__label {
    margin-left: @grid-size / 2;
  }

  &__progress {}
}
