@import '~styles/vars.less';

.topbar {
  position: relative;
  z-index: 1;

  padding: 0 16px;
  min-height: 56px;

  background: #fff;

  &--has-left-button {
    padding-left: 56px;
  }

  &--has-right-button {
    padding-right: 56px;
  }

  &--has-title {
    padding-left: 56px;
    padding-right: 56px;
  }

  &__title {
    padding-top: 16px;

    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
  }

  &__left-button-wrapper,
  &__right-button-wrapper {
    position: absolute;
    display: flex;

    align-self: flex-start;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    top: 0;
  }

  &__left-button-wrapper {
    left: 0;
  }

  &__right-button-wrapper {
    right: 0;
  }

  &__back-button {
    cursor: pointer;
  }

  &__close-button {
    position: relative;

    width: 24px;
    height: 24px;

    border-radius: 100%;

    background: @color-neutral-200;

    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;

      width: 14px;
      height: 2px;
      top: 11px;
      left: 5px;

      border-radius: 8px;
      background: @color-primary;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
