@font-face {
    font-family: 'Segoe UI';
    src: url('~fonts/SegoeUI.woff2') format('woff2'),
        url('~fonts/SegoeUI.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('~fonts/SegoeUI-SemiBold.woff2') format('woff2'),
        url('~fonts/SegoeUI-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('~fonts/SegoeUI-Bold.woff2') format('woff2'),
        url('~fonts/SegoeUI-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Mono';
    src: url('~fonts/SpaceMono-Bold.woff2') format('woff2'),
        url('~fonts/SpaceMono-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
