@import '~styles/vars.less';

.subtopic-grammar-exercise.subtopic-words-widget {
  margin-top: 0;

  & + & {
    margin-top: @grid-size;
  }
}

.subtopic-summary {
  display: flex;
  flex-direction: row;

  justify-content: space-around;
  gap: @grid-size;

  &__item {
    width: 100%;
    padding: @grid-size 0;

    border-radius: @border-radius;
    background: @color-neutral-300;

    text-align: center;
    color: @color-text-500;

    &-number {
      font-family: 'Space Mono';
      font-size: 24px;
    }
  
    &-caption {
      font-size: 12px;
    }
  }
}

.subtopic-words-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  gap: @grid-size;
  margin-top: @grid-size;

  cursor: pointer;

  &__icon {
    display: flex;
    justify-content: center;

    align-items: center;
    width: 48px;
    height: 48px;

    background: @color-neutral-200;
    border-radius: 100%;

    font-size: 24px;
  }

  &__title {
    flex-grow: 1;

    font-weight: 600;

    span {
      color: @color-text-500;
      font-weight: 400;
    }
  }

  &__arrow {
    position: relative;

    width: 24px;
    height: 24px;

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;

      width: 11px;
      height: 2px;
      left: 8px;

      border-radius: 8px;
      background: @color-primary;
    }

    &::before {
      transform: rotate(45deg);

      top: 8px;
    }

    &::after {
      transform: rotate(-45deg);

      top: 15px;
    }
  }
}

.subtopic-situation {
  display: flex;
  flex-direction: row;

  gap: @grid-size / 2;

  &__progress-wrapper {
    display: flex;
    flex-direction: column;

    align-items: center;

    &::before,
    &::after {
      content: '';
      display: block;

      width: 6px;
      flex-grow: 1;

      background: @color-neutral-300;
    }

    &::before {
      margin-bottom: -1px;
    }

    &::after {
      margin-top: -1px;
    }
  }

  &:first-child &__progress-wrapper::before {
    background: none;
  }

  &:last-child &__progress-wrapper::after {
    background: none;
  }

  &__progress {
    z-index: 1;
  }

  &__main {
    display: flex;
    flex-grow: 1;

    gap: @grid-size;
    margin: @grid-size / 2 0;

    cursor: pointer;
  }

  &:first-child &__main {
    margin-top: 0;
  }

  &:last-child &__main {
    margin-bottom: 0;
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
  }

  &__description {
    margin-top: @grid-size / 2;

    color: @color-text-500;
    line-height: 22px;
  }

  &__duration {
    margin-top: @grid-size;
  }

  &__img {
    overflow: hidden;

    width: 80px;
    height: 140px;
    min-width: 80px;

    border-radius: @border-radius / 2;

    img {
      width: 100%;
    }
  }

  &--locked &__main {
    cursor: default;
  }

  &--locked &__title {
    color: @color-text-500;
  }
}

.subtopic-exercise {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  gap: @grid-size;
  padding: @grid-size;

  background: @color-neutral-200;
  border-radius: @border-radius;

  text-align: left;

  cursor: pointer;

  &__icon {
    display: flex;
    justify-content: center;

    align-items: center;
    width: 48px;
    height: 48px;

    background: @color-neutral-0;
    border-radius: 100%;

    font-size: 24px;
  }

  &__title {
    flex-grow: 1;

    font-weight: 600;

    span {
      color: @color-text-500;
      font-weight: 400;
    }
  }

  &__progress {}

  & + & {
    margin-top: @grid-size / 2;
  }

  &--locked {
    cursor: default;
  }

  &--locked &__title {
    color: @color-text-500;
  }
}
