@import '~styles/vars.less';

.button-group {
  display: flex;

  gap: @grid-size / 2;

  &--direction-row {
    flex-direction: row;
  }

  &--direction-col {
    flex-direction: column;
  }
}
