@max-width: 430px;
@min-width: 320px;
@min-height: 568px;
@popup-width: 300px;

@border-radius: 16px;
@grid-size: 16px;

@iphone-gte-10-indent-bottom: 16px;
@iphone-gte-10-indent-top: 50px;
@ipgone-lte-8-indent-top: 30px;

// Colors
@color-neutral-0: #fff;
@color-neutral-200: #EDF1F4;
@color-neutral-300: #DDE6EB;
@color-neutral-500: #CAD4DB;

@color-primary: #667F8E;
@color-secondary: #613EE8;
@color-secondary-linear: linear-gradient(147.85deg, @color-secondary 12.56%, #A73EE8 80.7%);

@color-text-700: #000;
@color-text-500: #879BA8;

@color-error: #F31E1E;
@color-success: #4EBC3C;
@color-warning: #FFA51F;
// End colors

@text-grey: #909090;
@text-blue: #613EE9;
@text-green: #4EBC3C;
@text-yellow: #FFA51F;

@block-yellow: #FFA51F;
@block-blue: #613EE9;
@block-grey: #f2f2f2;
@block-red: #F31E1E;
@block-green: #4EBC3C;
@block-light-blue: #efecfd;
@block-light-yellow: #fff6e9;
@block-light-green: #edf8ec;
@block-light-red: #FCEBEA;

@border-grey: #e6e6e6;
@border-red: #F31E1E;
@border-light-red: #F31E1E1A;
@border-green: #4EBC3C;
@border-light-green: #EDF8EC; // #4EBC3C1A;

@bg-default: #EDF1F4;

.image-container-mixin(@height:55%) {
  width: 100%;
  padding-top: @height;
}

:root {
  --color-neutral-0: @color-neutral-0;
  --color-neutral-200: @color-neutral-200;
  --color-neutral-300: @color-neutral-300;
  --color-neutral-500: @color-neutral-500;

  --color-primary: @color-primary;
  --color-secondary: @color-secondary;
  --color-secondary-linear: @color-secondary-linear;

  --color-text-700: @color-text-700;
  --color-text-500: @color-text-500;

  --color-error: @color-error;
  --color-success: @color-success;
  --color-warning: @color-warning;
}
