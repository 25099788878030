@import '~styles/vars.less';

.radial-progress-state {
  border-radius: 100%;

  &__not-started-icon {
    width: 8px;
    height: 8px;

    border-radius: 100%;
    background: @color-secondary-linear;
  }

  &__icon-lock {
    position: relative;

    width: 20px;
    height: 20px;
    top: -1px;

    background-image: url('~images/svg/icon-lock-20.svg');
  }

  &--not-started-style-arrow {
    background: @color-primary;
  }

  &--not-started-style-arrow .radial-progress-bar__svg {
    display: none;
  }

  &--not-started-style-arrow &__not-started-icon {
    width: 13px;
    height: 13px;

    border-radius: 0;
    background: url('~images/svg/icon-pointer.svg') no-repeat;
  }

  &--size-normal {
    width: 32px;
    height: 32px;
  }

  &--size-small {
    width: 24px;
    height: 24px;
  }

  &--locked {
    display: flex;
    justify-content: center;

    align-items: center;

    background: @color-neutral-300;
  }

  &--completed {
    display: flex;
    justify-content: center;

    align-items: center;

    background: @color-secondary-linear;
  }
}
