@import '~styles/vars.less';

.layout {
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;

  background: @bg-default;

  font-family: 'Segoe UI';
  font-weight: 400;
  font-size: 16px;

  &__inner {
    position: relative;
    box-sizing: border-box;

    max-width: @max-width;
    min-width: @min-width;
    margin: 0 auto;
  }

  &__header {
    z-index: 1;

    box-sizing: border-box;

    &--bg-white {
      background: #fff;
      box-shadow: 0px 1px 2px @color-neutral-200;
    }
  }

  &__footer {
    position: sticky;
    box-sizing: border-box;

    bottom: 0;
    width: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  
    flex-grow: 1;
  }

  &__body {
    flex-grow: 1;
  }

  &__body-inner {
    position: relative;
    box-sizing: border-box;

    max-width: @max-width;
    min-width: @min-width;
    margin: 0 auto;
    height: 100%;
  }

  &__row {
    box-sizing: border-box;

    padding: @grid-size;
  }

  &--no-scroll &__body {
    overflow: hidden;
  }

  &--bg-white {
    background: #fff;
  }
}

.device-iphone-lte-8 {
  & .layout__header {
    padding-top: 30px;
  }
}

.device-iphone-gte-10 {
  & .layout__header {
    padding-top: 50px;
  }

  & .layout__footer {
    padding-bottom: 20px;
  }
}
