@import '~styles/vars.less';

.grammar-exercise {
  box-sizing: border-box;

  &__inner {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    height: 100%;
    padding-top: 0;
  }

  &__rule {
    margin-bottom: @grid-size;
    padding: @grid-size;

    border-radius: @border-radius;

    background: @color-neutral-200;
  }

  &__question {
    display: flex;
    flex-direction: column;

    margin-bottom: @grid-size;
    padding: @grid-size * 2 @grid-size;
    justify-content: center;
    align-items: center;

    border: 1px solid @border-grey;
    border-radius: @border-radius;
    background: @color-neutral-0;

    &-text {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      // line-height: 30px;
    }
  }

  &__clues {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    margin-bottom: @grid-size;
    padding: @grid-size * 2 @grid-size;

    border-radius: @border-radius;

    background: @color-neutral-200;
  }

  &__clue {
    padding: 9px 16px 11px;

    background: @color-neutral-300;
    border-radius: @border-radius;

    line-height: 20px;
    font-size: 14px;
    color: @color-primary;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: @grid-size;

    border-radius: @border-radius;
    border: 1px solid @border-grey;
  }

  &__text {
    // display: flex;
    // flex-direction: row;

    // justify-content: center;

    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 36px;
    // line-height: 30px;
  }

  &__ending {
    display: inline-block;

    margin-right: 6px;

    color: @color-secondary;
  }

  &__ending-gap {
    display: inline-block;
    position: relative;
    box-sizing: border-box;

    margin: 0 12px 0 2px;
    // top: 8px;
    // height: 32px;
    top: 6px;
    height: 27px;
    width: 40px;

    border-radius: @border-radius / 2;
    border: 1px solid @color-neutral-300;
    background: #fff;

    &--current {
      border: 2px solid @color-secondary;
    }
  }

  &__translation {
    margin-top: @grid-size / 2;

    color: @color-text-500;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }

  &__placeholder {
    padding-top: 8px;
    margin: 4px !important;

    font-size: 16px;
    line-height: 20px;
  }

  &__options {
    position: relative;
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    margin-top: @grid-size;
    // padding: 0 @grid-size;

    // &::before {
    //   display: block;
    //   content: attr(data-number);
    //   position: absolute;

    //   top: 3px;
    //   left: 0;
    //   padding: 4px;

    //   background: @color-neutral-200;
    //   border-radius: 4px;

    //   font-family: 'Space Mono';
    //   font-weight: 700;
    //   font-size: 12px;
    //   line-height: 10px;
    //   color: @color-text-500;
    // }
  }

  &__options-overlay {
    display: flex;
    position: absolute;
    justify-content: center;
    z-index: 1;

    align-items: center;
    width: ~'calc(100% + 32px)';
    height: ~'calc(100% + 32px)';
    top: -@grid-size;
    left: -@grid-size;

    backdrop-filter: blur(4px);

    &-button {
      display: flex;
      flex-direction: row;
      justify-content: center;

      align-items: center;
      width: 80px;
      height: 40px;
      
      border-radius: @border-radius;
      background: @color-secondary;

      cursor: pointer;
    }
  }

  &__option {
    margin: 4px;
  }

  &__placeholders {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    margin-top: @grid-size;
  }

  &__placeholder {
    margin: 4px;
  }
}

.device-iphone-gte-10 {
  & .grammar-exercise__options {
    padding-bottom: @iphone-gte-10-indent-bottom;
  }
}
