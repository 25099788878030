@import '~styles/vars.less';

.label {
  display: inline-block;
  box-sizing: border-box;

  padding: 5px 8px 5px 8px;
  height: 24px;

  border-radius: @border-radius / 2;
  background: @color-neutral-200;

  font-size: 12px;
  font-weight: 600;
  color: @color-text-500;

  &--highlight-1 {
    background: @color-neutral-300;
  }
}

.label-box {
  padding: @grid-size 0;

  text-align: center;
}
