@import '~styles/vars.less';

.vocabulary-words-modal {
  &__note {
    padding: 10px @grid-size;
    margin-bottom: @grid-size;

    background: @color-neutral-200;
    border-radius: @border-radius / 2;

    text-align: left;
    color: @color-text-500;
    line-height: 24px;
  }

  &__words {
    box-sizing: border-box;

    max-height: 60vh;
    overflow-y: auto;
  }

  &__button {
    margin-top: @grid-size;
  }

  &__category-select {
    width: 100%;
    height: 48px;
    margin-bottom: @grid-size;
    padding: 0 @grid-size / 2;
    
    border: 1px solid @color-neutral-300;
    border-radius: @border-radius / 2;
  }

  &-count-setter {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch;

    height: @grid-size * 2;
    margin-bottom: @grid-size;

    font-size: 12px;
    font-weight: 600;

    border-radius: @border-radius / 2;
    background: @color-neutral-200;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;

      color: @color-text-500;

      border-radius: @border-radius / 2;

      cursor: pointer;

      &.active {
        background: @color-neutral-500;

        color: @color-text-700;

        cursor: default;
      }
    }
  }
}