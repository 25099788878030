@import '~styles/vars.less';

.vocabulary-word {
  position: relative;
  display: flex;

  text-align: left;

  cursor: default;

  &--playable {
    padding-left: @grid-size * 2;

    cursor: pointer;
  }

  & + & {
    // margin-top: @grid-size + @grid-size / 2;

    border-top: 1px solid @color-neutral-200;
    padding-top: @grid-size;
    margin-top: @grid-size;
  }

  &__play-button {
    position: absolute;
  
    left: 0;
  }

  &__content {
    flex-grow: 1;
  }

  &__text {
    padding-top: 2px;

    font-size: 16px;
    font-weight: 600;
  }

  &__translation {
    margin-top: @grid-size / 4;

    font-size: 12px;
    color: @color-text-500;
  }

  &__progress {
    align-self: center;
  }

  &__remove {
    position: relative;

    align-self: center;
    width: 24px;
    height: 24px;

    border-radius: 100%;
    background: @color-neutral-200;

    cursor: pointer;

    &::before {
      content: '';
      display: block;
      position: absolute;

      width: 10px;
      height: 2px;
      left: 7px;
      top: 11px;

      background: @color-primary;
      border-radius: 8px;
    }
  }

  &--checkbox &__inner {
    cursor: pointer;

    &::before {
      box-sizing: border-box;
      content: '';
      position: absolute;
      display: block;

      width: 20px;
      height: 20px;
      left: -32px;
      top: 2px;

      border: 1px solid @border-grey;
      border-radius: 100%;
    }
  }

  &--checkbox-checked &__inner::before {
    border: none;
    background: @block-blue;
  }
}
