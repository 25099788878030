@import '~styles/vars.less';

.exercise-info {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    flex-grow: 1;
    align-items: center;
  }

  &__icon {
    display: flex;
    justify-content: center;

    align-items: center;
    width: 80px;
    height: 80px;
    margin-bottom: @grid-size * 2;

    background: @color-neutral-200;
    border-radius: 100%;

    text-align: center;
    font-size: 40px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  &__features {
    width: 100%;
    margin-top: @grid-size * 2;
  }

  &__feature {
    overflow: hidden;

    color: @color-text-500;

    & + & {
      margin-top: @grid-size;
    }

    &-icon {
      display: flex;

      float: left;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-right: @grid-size;

      border-radius: 100%;

      background: @color-neutral-200;
    }

    &-text {
      padding-top: 6px;

      line-height: 22px;
    }
  }

  // &__tasks {
  //   margin-top: @grid-size * 2;
  //   padding: @grid-size;

  //   border-radius: @border-radius;
  //   background: @block-light-blue;

  //   &__title {
  //     margin-bottom: @grid-size;

  //     font-weight: 600;
  //     text-align: center;
  //   }
  // }

  // &__task {
  //   &-label {
  //     display: inline-block;

  //     padding: 4px 10px;
  //     margin-right: @grid-size / 2;

  //     border-radius: 8px;

  //     background: #fff;

  //     font-size: 12px;
  //     font-weight: 600;
  //     color: @text-grey;
  //     // font-family: 'Space Mono';

  //     cursor: default;
  //   }

  //   & + & {
  //     margin-top: 8px;
  //   }
  // }

  // &__skip-button {
  //   margin-top: @grid-size / 2;
  // }
}

.exercise-info-task-modal {
  &__task {
    text-align: left;

    padding: @grid-size 0;

    border-top: 1px solid @border-grey;

    &:first-child {
      padding-top: 0;

      border: none;
    }
  }
}
