@import '~styles/vars.less';

.linear-progress-bar {
  display: flex;

  align-items: center;

  &__scale {
    overflow: hidden;

    width: 100%;
    height: 6px;

    background: @color-neutral-200;
    border-radius: 8px;

    span {
      display: block;

      width: 50%;
      height: 100%;

      background: @color-secondary-linear;
      border-radius: 8px;
    }
  }
}
