@import '~styles/vars.less';

.exercise-end {
  display: flex;
  flex-direction: column;

  padding: 0 @grid-size @grid-size;
  height: 100%;
  justify-content: space-between;
  gap: @grid-size;

  &__icon {
    display: flex;
    justify-content: center;

    align-items: center;
    width: 80px;
    height: 80px;

    border-radius: 100%;
    background: @color-neutral-200;

    font-size: 40px;
  }

  &__img {
    position: relative;
    overflow: hidden;

    .image-container-mixin(65%);

    border-radius: 16px;

    img {
      position: absolute;

      left: 0;
      top: 0;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    justify-content: center;
    align-items: center;
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    gap: @grid-size / 2;
  }

  &__h1 {
    font-size: 20px;
    font-weight: 700;
  } 

  &__text {
    max-width: 80%;

    line-height: 24px;
    text-align: center;
    color: @color-text-500;
  }

  .device-iphone-gte-10 & {
    padding-bottom: 36px;
  }
}
