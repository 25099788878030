.loading {
  position: fixed;
  z-index: 9999;

  left: 0;
  width: 100%;
  height: 100%;

  background: #fff url('~images/svg/loader.svg') center center no-repeat;
}
