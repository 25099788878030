@import '~styles/vars.less';

.phrases-exercise {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  height: 100%;
  padding: 0 @grid-size @grid-size;

  &__img {
    width: 100%;
    margin-bottom: 16px;

    position: relative;
    overflow: hidden;

    height: 0;
    padding-top: 65%;

    border-radius: 16px;
    background: @color-neutral-200;

    &-inner {
      position: absolute;

      width: 100%;
      height: 100%;
      top: 0;

      img {
        height: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: @grid-size;

    border-radius: @border-radius;
    border: 1px solid @border-grey;
  }

  &__question {
    display: flex;
    flex-direction: column;

    margin-bottom: @grid-size;
    padding: @grid-size * 2 @grid-size;
    justify-content: center;
    align-items: center;

    border: 1px solid @border-grey;
    border-radius: @border-radius;
    background: @color-neutral-0;

    &-text {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      // line-height: 30px;
    }
  }

  &__question &__translation {
    margin-top: 4px;
  }

  &__text {
    margin-top: @grid-size;

    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
  }

  &__translation {
    margin-top: @grid-size / 2;

    color: @color-text-500;
    text-align: center;
  }

  &__footer {
    position: relative;

    margin-top: @grid-size;

    text-align: center;
  }

  &__option {
    // transition: color 0.2s, background 0.5s;

    &--failed {
      background: @block-red;
      color: #fff;

      transition: color 0.2s, background 0.5s;
    }

    &--success {
      background: @block-green;
      color: #fff;

      transition: none;
    }

    & + & {
      margin-top: @grid-size / 2;
    }
  }

  &__options-overlay {
    display: flex;
    position: absolute;
    justify-content: center;
    z-index: 1;

    align-items: center;
    width: ~'calc(100% + 32px)';
    height: ~'calc(100% + 32px)';
    top: -@grid-size;
    left: -@grid-size;

    backdrop-filter: blur(4px);

    &-button {
      display: flex;
      flex-direction: row;
      justify-content: center;

      align-items: center;
      width: 80px;
      height: 40px;
      
      border-radius: @border-radius;
      background: @color-secondary;

      cursor: pointer;
    }
  }

  &__gap-options {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

    .exercise-gap-option {
      margin: 4px;
    }
  }

  &__gap-placeholders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;
    margin-top: @grid-size;

    .exercise-gap-placeholder {
      margin: 4px;
    }
  }

  &__gap-placeholder--letter {
    width: 46px;
  }

  &__button-play,
  &__button-record {
    display: inline-block;

    width: 56px;
    height: 56px;
    margin: 0 4px;

    border-radius: 100%;
    background: @color-neutral-200;

    cursor: pointer;

    &.disabled {
      opacity: 0.5;

      cursor: default;
    }
  }

  &__button-play {
    background: @color-neutral-200 url('~images/svg/icon-play.svg') center center no-repeat;

    &--playing {
      position: relative;

      background-image: none;

      &::before {
        position: absolute;
        display: block;
        content: '';

        width: 16px;
        height: 16px;
        top: 20px;
        left: 20px;

        background: @block-blue;
        border-radius: 2px;
      }
    }
  }

  &__button-record {
    background: @block-light-blue url('~images/svg/icon-record-blue.svg') center center no-repeat;
  }

  &__controls {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
  }

  &__help-text {
    padding: 4px 8px;

    border-radius: @border-radius / 2;
    background: @block-grey;

    color: @text-grey;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}

.phrases-exercise-text {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 30px;

  &__icon {
    position: relative;

    margin-right: 8px;
    top: 5px;
  }

  &--playable {
    cursor: pointer;
  }

  &--playing &__icon,
  &--play-disabled &__icon {
    opacity: 0.5;
  }

  &--play-disabled {
    cursor: default;
  }

  &__ending-gap {
    display: inline-block;
    position: relative;
    box-sizing: border-box;

    margin: 0 12px 0 2px;
    // top: 8px;
    // height: 32px;
    top: 6px;
    height: 27px;
    width: 40px;

    border-radius: @border-radius / 2;
    border: 1px solid @color-neutral-300;
    background: #fff;

    &--current {
      border: 2px solid @color-secondary;
    }
  }

  &__highlighted-text {
    color: @color-secondary;
  }

  span + span {
    padding-left: 6px;
  }
}

.phrases-exercise-task-description {
  position: absolute;
  box-sizing: border-box;

  width: 100%;
  padding: @grid-size;
  bottom: 0;

  &__inner {
    position: relative;
    padding: @grid-size;

    border-radius: @border-radius / 2;
    background: @color-neutral-200;

    text-align: center;
  }

  &__close {
    position: absolute;

    top: @grid-size;
    right: @grid-size;
    width: 24px;
    height: 24px;

    border-radius: 100%;

    background: @color-neutral-300;

    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;

      width: 14px;
      height: 2px;
      top: 11px;
      left: 5px;

      border-radius: 8px;
      background: @color-primary;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__title {
    line-height: 22px;
  }

  &__text {}
}

.device-iphone-gte-10 {
  & .phrases-exercise__footer {
    padding-bottom: @iphone-gte-10-indent-bottom;
  }
}
