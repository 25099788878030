@import '~styles/vars.less';

.exercise-topbar {
  &__content {
    display: flex;
    flex-direction: row;

    align-items: center;
    min-height: 56px;
  }

  &__round {
    margin-right: @grid-size;

    color: @color-primary;

    cursor: default;
  }

  &__progress-bar {
    overflow: hidden;

    flex-grow: 1;
    height: 6px;

    border-radius: 10px;

    background: @color-neutral-200;

    span {
      display: block;

      height: 100%;

      background: @color-secondary;
      border-radius: 8px;

      transition: all .4s linear 0s;
    }
  }

  &--transparent {
    background: none;
  }

  &--transparent &__progress-bar {
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.2);

    span {
      background: #fff;
    }
  }

  &--transparent &__round {
    backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.2);

    color: #fff;
  }

  &--transparent .topbar__close-button {
    background: #fff;

    &::before,
    &::after {
      background: #666666;
    }
  }
}
