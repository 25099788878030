.login {
  background: #613EE9;

  color: #fff;
  text-align: center;

  &__inner {
    display: flex;
    box-sizing: border-box;

    padding: 0 16px 34px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &__logo {
    margin-bottom: 10px;

    min-width: 234px;
    height: 40px;

    background: url('~images/svg/login-logo.svg') center center no-repeat;
  }

  &__slogan {
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);
    line-height: 24px;
  }

  &__button {
    position: relative;

    width: 100%;

    border: 1px solid rgba(255, 255, 255, 0.3);
    background: none;

    color: #fff;

    & + & {
      margin-top: 16px;
    }

    &--fb,
    &--vk,
    &--google {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: block;

        width: 32px;
        height: 32px;
        top: 11px;
        left: 11px;
      }
    }

    &--fb::before {
      background: url('~images/svg/social-fb.svg') no-repeat;
    }

    &--vk::before {
      background: url('~images/svg/social-vk.svg') no-repeat;
    }

    &--google::before {
      background: url('~images/svg/social-google.svg') no-repeat;
    }
  }

  &__buttons-title {
    margin-bottom: 20px;

    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 600;
  }
}
